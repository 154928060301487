import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero/hero"
import RightCta from "../components/cta/right-bordered/cta"
// import LeftCta from "../components/cta/left-bordered/cta"
// import Savings from "../components/savings/savings"

import desktopHero from "../images/benefits/2.0_1440x540_Desktop_Hero_1X.png"
import mobileHero from "../images/benefits/2.0_543x800_Mobile_Hero_2X.png"
// import clipboard from "../images/talking-with-your-doctor/clipboard.png"
import gradientOne from "../images/benefits/gradientOne.png"
// import ctaBackground from "../images/benefits/ctaBackground.svg"
// import doctorPatient from "../images/benefits/2.0_750x402_Doctor_Patient_Desktop_1X.png"
// import doctorPatientMobile from "../images/benefits/2.0_543x300_Doctor_Patient_Mobile_2X.png"
// import vascepaPill from "../images/benefits/crystal-heart.png"
// import vascepaPillMobile from "../images/cardiovascular-risk/glassHeartMobile.png"
import vascepaPill from "../images/protect-against-another-heart-attack/04-no_handv4.png"
import vascepaPillMobile from "../images/protect-against-another-heart-attack/04-no_handv4.png"


export default () => (
  <Layout>
    <SEO
      title="Benefits of VASCEPA® (icosapent ethyl)"
      keywords="VASCEPA benefits, benefits of VASCEPA"
      description="Discover how FDA-approved prescription VASCEPA® (icosapent ethyl) capsules can help protect your heart. Please see Indication and Important Safety Information."
    />
    <h1 class="d-none">
      The VASCEPA clear difference 25% more heart protection
    </h1>
    <Hero
      desktopHeroBackground={desktopHero}
      mobileHeroBackground={mobileHero}
      alt="Man smiling"
      customColumnClass="benefits-header"
      headline={[
        "The VASCEPA",
        <br />,
        " clear difference:",
        <br />,
        <span class="hero-content-lg"> 25% added</span>,
        <br />,
        " heart protection with statins",
        <span class="un-bold">*</span>
      ]}
      content={[
        "Discover how FDA-approved prescription VASCEPA capsules can help protect your heart.",
        <br />,
        <p
          style={{
            display: "inline-block",
            marginTop: "10px",
            textIndent: "-5px",
          }}
          className="footnote-font"
        >
          *In a clinical study, patients treated with VASCEPA and a statin had fewer
          cardiovascular events (17.2%) compared to those who took placebo
          (22%).
        </p>,
      ]}
    />

    <RightCta
      colOne="5"
      colTwo="7"
      whiteborder="whiteborder"
      image={vascepaPill}
      mobileImage={vascepaPillMobile}
      background={gradientOne}
      alt="Vascepa Capsule and bottle"
      headline={[
        <span style={{ fontWeight: "normal" }}>
          Learn more about the groundbreaking cardiovascular risk reduction of
          VASCEPA.
        </span>,
      ]}
      cta={["See the proven facts ", <span aria-hidden="true">&gt;</span>]}
      url="/benefits/clinical-trial-results"
      mobileImageClass="img-fullheight"
      imgSM="mobile-height-150 bottle-img-container"
      imageLabel="Capsule is not actual size."
    />

    {/* <LeftCta
          ctaBackground = {ctaBackground}
          colOne="5"
          colTwo="7"
          image={doctorPatient}
          mobileImage={doctorPatientMobile}
          alt="Patient with her doctor discussing VASCEPA® (icosapent ethyl)"
          backgroundColor = "#E7E8EA"
          headline="Hear what patients and doctors are saying about VASCEPA."
          cta={["True VASCEPA Stories", <span aria-hidden="true">&gt;</span>]}
          headlineColor = "#1D355E"
          ctaColor = "#1D355E"
          url="/benefits/patient-stories"
          contentSubClass="left"
          ctaSubClass="left"
          /> */}
  </Layout>
)
